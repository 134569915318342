*{
	font-family: $ubuntu !important;
}
.row{
	max-width: 75% !important;
}

.btn{
	padding: 18px;
	text-transform: uppercase;
	font-weight: $bold;
	font-size: $button-size;
}
.btn-primary{
	background: $brown;
	color: $white;
	transition: 0.4s;
	border: 0px !important;
}
.btn-primary:hover{
	transition: 0.4s;
	background: $dark-green;
}
.btn-border{
	border: 1px solid $brown;
	color: $brown;
	transition: 0.4s;
}
.btn-border:hover{
	background: $brown;
	color: $white;
	transition: 0.4s;
}
.no-padding{
	padding: 0px !important;
}
input, select{
	border: 1px solid $light-green !important;
	height: 50px !important;
}
select{
	color: $black !important;
	font-weight: $bold;
	font-family: $ubuntu !important;
}
p{
	font-size: $normal-size !important;
	font-weight: $light;
}
.cabecera{
	width: 100%;
	text-align: center;
	position: relative;
	h1{
		font-size: $banner-size;
		font-weight: $light;
		padding-top: $large-margin;
		color: $white;
		padding-bottom: $large-margin;
	}
}
#menu{
	nav{
		ul{
			list-style: none;
			display: inline-block;
			li{
				display: inline;
			}
		}
		.searchGlobal{
			width: auto;
			float: right;
			margin-top: 17.5px;
			height: 35px !important;
			background-image: url('../img/search-icon.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: left;
			padding-left: $normal-margin;
		}
		p{
			margin: 0px;
		}
	}
	.sub{
		background: $brown;
		color: $white;
		.row{
			display: table;
			width: 75%;
			padding-top: 5px;
			padding-bottom: 5px;
			form{
				display: inline-block;
				.idioma{
					text-align: left;
					.active, .inactive{
						height: auto !important;
						border: 0px !important;
					}
					.active{
						background: $white;
						color: $brown;
						font-weight: $bold;
						padding: 2px;
					}
					.inactive{
						background: none;
					}
				}
			}
			
			a{
				color: $white;
			}
			.acceso{
				text-align: right;
				float: right;
				margin-left: $small-margin;
			}
			.idioma, .acceso{
				font-size: $small-size;
				display: table-cell;
				vertical-align: middle;
				cursor: pointer;
			}
		}		
	}
	.principal{
		.row{
			ul{
				margin: 0px;
				padding: 0px;
				width: auto;
				padding-top: $mini-margin;
				padding-bottom: $mini-margin;
				li{
					font-weight: $light;
					color: $black;
					margin-right: $normal-margin;
					font-size: $button-size;
					img{
						width: 200px;
					}
					a{
						text-align: left;
						color: $black;
					}
					a.registro{
						color: $brown;
					}
				}
			}
		}

	}	
}
#home{
	header{
		background-image: url('../img/header-home-02.jpg');
		background-size: cover;
		text-align: center;
		background-position: bottom;
		.medium-8{
			width: 100%;
			h1{
				font-size: $header-size;;
				color: $white;
				font-weight: $light;
				margin-top: 160px;
			}
			.input-group{
				margin-top: $normal-margin;
				padding-bottom: 160px;
				input, button{
					display: inline-block;
					width: auto;
					height: 70px !important;
				}
				input{
					width: 60%;
					margin-right: $normal-margin;
					padding-left: 30px;
					font-size: $title-size;
					font-weight: $light;
				}
				button{
					padding-left: 30px;
					padding-right: 30px;
					font-size: $important-size;
				}
			}
		}	
		
	}
	.destacados{
		padding-top: $large-margin;
		margin-bottom: $large-margin;
		.medium-7.columns{
			width: 100%;
		}
		h2{
			text-align: center;
			font-size: $title-size;
			color: $brown;
			font-weight: $bold;
			margin-bottom: $large-margin;			
			span{
				font-size: $header-size;
				color: $dark-green;
				font-weight: $light;
				line-height: 1;
			}
		}
		.escapada{
			background-size: cover;
			position: relative;
			overflow: hidden;
			height: 48%;
			.background{
				height: 100%;
				position: absolute;
				background: #000;
				width: 100%;
				opacity: 0.35;
			}
			.info{
				padding: $small-margin;
				position: relative;
				h3{
					font-size: $title-size;
					color: $light-green;
					font-weight: $bold;
					line-height: 1;
				}
				p{
					color: $white;
					margin-bottom: $small-margin;
				}
				.alojamiento, .fechas{
					font-weight: $bold;
				}
				.alojamiento{
					margin: 0px;
				}
			}
		}
		.escapada:nth-child(1){
			margin-bottom: 5%;
		}
		.escapada:nth-child(2){
			margin-top: 5%;
		}
		.casa-destacada{
			height: 200px;
			background-size: cover;
			position: relative;
			margin-bottom: $small-margin;
			h4{
				 width: 100%;
			     font-weight: $bold;
			     color: $white;
			     background: rgba(77, 55, 37, 0.75);
			     position: absolute;
			     font-size: $normal-size;
			     text-align: center;
			     padding: $mini-margin;
			     bottom: 0;
			     margin: 0px; 
			}
		}
		.casa-destacada:nth-child(2){
			margin-top: 6%;
			margin-bottom: 6%;
		}
	}
	.cta-alojamiento{
		padding-top: $normal-margin;
		padding-bottom: $large-margin;
		h2{
			font-size: $header-size;
			text-align: center;
			color: $dark-green;
			font-weight: $light;
			margin: 0px;
			.btn{
				vertical-align: middle;
				margin-left: $small-margin;
				margin-top: -10px;
			}
			span{
				font-weight: $bold !important;
			}
		}
		
	}
	.ofertas{
		padding-bottom: $normal-margin;
		h2{
			text-align: center;
			font-weight: $bold;
			color: $brown;
			font-size: $title-size;
			margin-bottom: $large-margin;
		}
		img{
			width: 100%;
		}
		h3{
			font-size: $title-size;
			font-weight: $light;
			margin: 0px;
			span{
				font-weight: $bold;
				color: $dark-green;
			}
		}
		.localidad, .oferta{
			font-weight: $bold;
		}
		.loacocalidad{
			color: $black;
			margin-bottom: $small-margin;
		}
		.oferta{
			color: $dark-green;
		}
		button{
			margin-top: $normal-margin;
		}
	}
	.turismo-rural .column:last-child:not(:first-child), .columns:last-child:not(:first-child){
		float: left;
	}
}
#mapa{
	background: $gray;
	margin-top: $large-margin;
	.medium-6.columns{
		form{
			float: none;
			display: inline-block;
		}
	}
	h2{
		margin-top: $large-margin;
		font-size: $title-size;
		color: $brown;
		line-height: 1;
		font-weight: $light;
		margin-bottom: $normal-margin;
		font-weight: $bold;
		span{
			font-size: $header-size;
			color: $dark-green;
			font-weight: $light;
		}
	}
	label{
		font-family: $normal-size;
		color: $dark-green;
		font-weight: $bold;
		margin-bottom: $small-margin;
	}
	select{
		margin-bottom: $normal-margin;
	}
	button{
		margin-bottom: $large-margin;
	}
}
#comunidades, #portugal{
	text-align: center;
	padding-top: $large-margin;
	padding-bottom: $large-margin;
	h2{
		font-size: $title-size;
		color: $brown;
		font-weight: $bold;
		margin-bottom: $large-margin;
		span{
			font-weight: $light;
			color: $dark-green;
			font-weight: $light;
			font-size: $header-size;
		}
	}
	.comunidad{
		margin-bottom: $normal-margin;
		img{
		height: 60px;
		margin-bottom: $small-margin;
		}
		h3{
			font-size: $button-size;
			color: $dark-green;
			font-weight: $bold;

		}
	}
	.columns:last-child{
		float: left;
	}
}
#portugal{
	background: $gray;
	padding-top: $large-margin;
	padding-bottom: $large-margin;
}
#otros{
	padding-top: $large-margin;
	padding-bottom: $large-margin;
	.blog, .solidarias, .interes{
		h2{
			font-size: $title-size;
			text-align: center;
			color: $brown;
			font-weight: $bold;
			margin-bottom: $normal-margin;
		}
		.post{
			margin-bottom: $normal-margin;
			img{
				width: 100%;
			}
			h3{
				font-size: $title-size;
				color: $dark-green;
				font-weight: $bold;
			}
			p{
				margin-top: $small-margin;
				margin-bottom: 0px;
			}
		}
		button{
			text-align: center;
		}
	}
	.interes, .solidarias{
		text-align: center;
		margin-bottom: $large-margin;
		a{
			display: block;
			font-size: $title-size;
			color: $dark-green;
			font-weight: $bold;
			margin-bottom: $small-margin,
		}
	}
}
#cta-footer{
	background-image: url('../img/cta.jpg');
	background-size: cover;
	text-align: center;
	background-position: center;
	h1{
		padding-top: $large-margin;
		margin-bottom: $normal-margin;
		color: $white;
		font-weight: $light;
	}
	button{
		margin-bottom: $large-margin;
	}
}
#banner-footer{
	background: $brown;
	text-align: center;
	padding-top: $large-margin;
	padding-bottom: $large-margin;
	img{
		width: $large-margin;
		margin-bottom: $normal-margin;
	}
	h3{
		font-size: $title-size;
		color: $white;
		font-weight: $bold;
	}
}
footer{
	background: #111;
	padding-top: $normal-margin;
	padding-bottom: $normal-margin;
	p, a{
		color: $white;
		font-size: $button-size;
		font-weight: $bold;
	}
	img{
		width: 30px;
		margin-right: 10px;
	}
}
#resultados, #ofertas{
	.cabecera{
		background-image: url('../img/header-home-02.jpg');
		background-size: cover;
		background-position: center;
		margin-bottom: $normal-margin;
	}
	h3{
		font-weight: $light;
		color: $dark-green;
		span{
			font-weight: $bold;
		}
	}
	.filtros, .ordenar{
		border: 1px solid $light-gray;
		padding: $small-margin;
		margin-top: $small-margin;
		label{
			font-weight: $bold;
			color: $dark-green;
			margin-bottom: $mini-margin;
			text-transform: uppercase;
		}
		input, select{
			margin-bottom: $small-margin;
			font-family: $ubuntu;
			font-weight: $light;
		}
		.checkbox{
			input[type=checkbox] {
    			display: none;
			}
			label{
				margin-left: 0px;
				font-weight: $light;
				text-transform: none;
				color: $black;
				font-size: $small-size;
			}
			.checkbox label:before {
	    		border-radius: 3px;
	    		margin-right: $mini-margin;
			}
			input[type=checkbox]:checked + label:before {
	    		content: " ";
	    		background: $dark-green;
	    		text-align: center;
	    		margin-right: $mini-margin;
			}
			label:before {
				margin-right: $mini-margin;
			    content: "";
			    display: inline-block;
			    width: 16px;
			    height: 16px;
			    left: 0;
			    background-color: $white;
			    border: 1px solid $medium-green;
			    vertical-align: middle;
			}			
		}
		.pais, .provincias, .tipo, .precio, .plazas, .otros{
			margin-bottom: $small-margin;
		}

		
	}
	.casa{
		border: 1px solid $light-gray;
		padding: $small-margin;
		margin-bottom: $normal-margin;
		position: relative;
		.precio{
			position: absolute;
			right: 0;
			top: 0;
			font-size: 10px !important;
			text-align: center;
			color: $white;
			background: $medium-green;
			padding: 5px;	
			span{
				font-size: 30px;
				line-height: 1;
				display: block;
				color: $white;
				font-weight: $bold;
			}
		}
		img{
			width: 100%;
		}
		h3{
			font-size: $title-size;
			line-height: 0.7;
			margin-bottom: $mini-margin;
		}
		h4{
			font-size: $button-size;
			font-weight: $light;
			margin-bottom: $small-margin;
		}
		p{
			margin-bottom: $small-margin;
		}
		.cats{
			p{
				font-size: $normal-size !important;
				margin-bottom: $mini-margin;
				margin-top: 0px;
				margin-bottom: 5px;
				color: $medium-green;
				display: inline-block;
				margin-right: $mini-margin;
			}
			a{
				margin-bottom: 0px;
				margin-right: $mini-margin;
				img{
					width: 20px;
					vertical-align: middle;

				}
			}
			span{
				color: $brown;
				font-weight: $bold;
				img{
					width: 20px;
					margin-right: 5px;
				}
			}
		}
		
	}
	.ordenar{
		border: 0px;
		margin-bottom: $small-margin;
		padding: 0px;
		h3{
			display: inline-block;
			width: auto;
		}
		select{
			width: 200px;
			float: right;
			padding-right: 20px;
			height: 35px !important;
		}
	}
	.resultados{
		margin-bottom: $normal-margin;
		.breadchumbs{
			a{
				color: $medium-green;
			}
		}
		p{
			margin-bottom: 0px;
			span{
				font-weight: $bold;
			}
		}
	}
}
#single-ofertas{
	padding-bottom: $large-margin;
	.cabecera{
		background-image: url('../img/ofertas-banner.jpg');
		background-size: cover;
		background-position: bottom;
		margin-bottom: $normal-margin;
		h1{
			font-size: $banner-size;
			font-weight: $light;
			span{
				font-weight: $bold;
				text-transform: lowercase;
				color: $light-green;
			}
		}
	}
	img{
		width: 100%;
	}
	h2{
		font-size: $banner-size;
		font-weight: $light;
		color: $dark-green;
		line-height: 0.7;
	}
	.localidad{
		font-size: $title-size !important;
		font-weight: $light;
		color: $brown;
		margin-top: $small-margin;
		margin-bottom: $normal-margin;
	}
	.precio, .plazas, .fechas{
		font-weight: $light;
		font-size: $important-size !important;
		margin-top: 0px !important;
		img{
			width: 25px;
			margin-right: $mini-margin;
		}
		span{
			font-weight: $bold;
			color: $medium-green;
		}
	}
	.btn{
		margin-top: $normal-margin;
	}
	.ofertas{
		padding-top: $large-margin;
		h2{
			text-align: center;
			font-weight: $bold;
			color: $brown;
			font-size: $title-size;
			margin-bottom: $large-margin;
		}
		.oferta{
			margin-bottom: $large-margin;
			img{
				width: 100%;
			}
			h3{
				font-size: $title-size;
				font-weight: $light;
				margin: 0px;
				span{
					font-weight: $bold;
					color: $dark-green;
				}
			}
			.localidad{
				margin-top: 0px;
				font-size: $important-size !important;
				font-weight: $bold;
				margin-bottom: $small-margin !important;
			}
			.oferta-text{
				font-weight: $bold;
				color: $medium-green;

			}
		}
		.btn{
			margin-top: 0px;
		}
	}
}
#casas-destacadas, #nuevos{
	.cabecera{
		background-image: url('../img/cabecera-casas-destacadas.jpg');
		background-size: cover;
	}
	.listado{
		margin-top: $normal-margin;
		a{
			color: $black;
		}
		.destacado{
			border-bottom: 3px solid $brown;
			margin-bottom: $large-margin;
			.casa{
				position: relative;
				height: 200px;
				h3{
					background: rgba(77,55,37,0.75);
					color: $white;
					font-weight: $bold;
					font-size: $button-size;
					text-align: center;
					position: absolute;
					padding-top: $mini-margin;
					padding-bottom: $mini-margin;
					width: 100%;
					bottom: 0;
					margin: 0px;
					span{
						font-size: $normal-size;
						font-weight: $light;
					}

				}
			}
			.info-destacada{
				margin-top: $small-margin;
				margin-bottom: $small-margin;
				p{
					font-weight: $bold;
					margin-bottom: 0px;
				}
				.precio{
					color: $medium-green;
				}
				.plazas{
					color: $brown;
				}
			}
			.descripcion{
				margin-bottom: $small-margin;
			}
			.tags{
				font-size: $small-size !important;
				color: $medium-green;
				span{
					font-weight: $bold;
					color: $black;
					margin-right: 5px;
				}
			}
			.tags:last-child{
				margin-bottom: $small-margin;
			}
		}
		.columns:last-child{
			float: left !important;
		}
	}
}
#ofertas{
	.cabecera{
		background-image: url('../img/cabecera-ofertas.jpg');
		background-size: cover;
		margin-bottom: $normal-margin;
	}
	h3{
		font-weight: $light;
		color: $dark-green;
		span{
			font-weight: $bold;
		}
	}
	.filtros, .ordenar{
		padding: $small-margin;
		margin-top: $small-margin;
		label{
			font-weight: $bold;
			color: $dark-green;
			margin-bottom: $mini-margin;
			text-transform: uppercase;
		}
		input, select{
			margin-bottom: $small-margin;
		}
	}
	.oferta{
		border: 1px solid $light-gray;
		padding: $small-margin;
		position: relative;
		margin-bottom: $small-margin;
		img{
			width: 100%;
		}
		h3{
			font-size: $title-size;
			margin-top: -5px;
			margin-bottom: $small-margin;
			color: $dark-green;
			font-weight: $light;
			width: 90%;
			span{
				display: block;
				font-weight: $bold;
			}
		}
		h4{
			font-size: $title-size;
			font-weight: $light;
			margin-bottom: 0px;
			color: $brown;
		}
		.localidad{
			font-size: $important-size !important;
			margin-top: 0px;
			font-weight: $light;
		}
		.plazas, .fechas{
			font-size: $button-size !important;
			margin-top: 0px;
			margin-bottom: $mini-margin;
			img{
				width: 25px;
				margin-right: $mini-margin;
			}
			span{
				color: $medium-green;
				font-weight: $bold;
			}
		}
		.precio{
			position: absolute;
			right: 0;
			top: 0;
			font-size: 10px !important;
			text-align: center;
			color: $white;
			background: $medium-green;
			padding: 5px;	
			span{
				font-size: 30px;
				line-height: 1;
				display: block;
				color: $white;
				font-weight: $bold;
			}
		}
	}
	.ordenar{
		border: 0px;
		margin-bottom: $small-margin;
		padding: 0px;
		label{
			display: inline-block;
			margin-right: $small-margin;
		}
		input, select{
			width: 200px !important;
			display: inline-block;
		}
	}
	.resultados{
		margin-bottom: $normal-margin;
		.breadchumbs{
			a{
				color: $medium-green;
			}
		}
		p{
			span{
				font-weight: $bold;
			}
		}
	}
}
#turismo-rural{
	.cabecera{
		background-image: url('../img/cabecera-turismo-rural.jpg');
		background-size: cover;
	}
	#mapa{
		background: $white;
		margin-top: $normal-margin;
		form{
			margin-top: $normal-margin;
		}
	}
	.resultados{
	 	h2{
	 		color: $dark-green;
	 		font-size: $header-size;
	 		font-weight: $light;
	 		margin-top: $large-margin;
	 		margin-bottom: $normal-margin;
	 		img{
	 			width: 60px;
	 			margin-right: $small-margin;
	 			vertical-align: sub;
	 		}
	 	}
	 	.text-center{
	 		img{
	 			width: 40px;
	 			margin-bottom: $small-margin;
	 		}
	 		h3{
	 			color: $dark-green;
	 			font-size: $button-size;
	 			margin-bottom: $normal-margin;
	 			span{
	 				font-weight: $bold;
	 			}
	 		}
	 	}
	 	.columns:last-child{
	 		float: left;
	 	}
	}
}
#nuevos{
	.cabecera{
		background-image: url('../img/cabecera-nuevos.jpg');
	}
}
#blog{
	.cabecera{
		background-image:url('../img/cabecera-blog.jpg');
		background-size: cover;
	}
	.post.principal{
		.fecha, h3{
			text-align: center;
		}
		.fecha{
			margin-top: $normal-margin;
			font-size: $title-size !important;
			color: $brown;
			font-weight: $bold;
			margin-bottom: 0px;
		}
		h3{
			font-size: $banner-size;
			font-weight: $light;
			color: $dark-green;
		}
		img{
			width: 100%;
			margin-top: $normal-margin;
			margin-bottom: $normal-margin;	
		}
		p{
			a{
				color: $medium-green;
			}
		}
		.tags{
			font-weight: $bold;
			color: $black;
			a{
				font-weight: $light;
				border-right: 1px solid $medium-green;
				padding-right: $mini-margin;
				margin-left: $mini-margin;
			}
			a:last-child{
				border-right: 0px;
			}
		}
		.comentarios{
			margin-top: $small-margin;
			h4{
				font-size: $title-size;
				color: $dark-green;
				border-bottom: 1px solid $gray;
				padding-bottom: $small-margin;
				font-weight: $bold;
			}
		}
	}
	.buscador, .suscribirse, .ultimas, .entradas, .categorias, .etiquetas{
		margin-top: $normal-margin;
		h4{
			font-weight: $bold;
			color: $dark-green;
			margin-bottom: $small-margin;
			img{
				margin-right: $small-margin;
			}
		}
		input{
			margin-bottom: $small-margin;
		}
		input, button{
			width: 100%;
		}
		ul{
			margin-left: $small-margin;
			li{
				color: $black;
				font-size: $normal-size;
				font-weight: $light;
				margin-bottom: $small-margin;
			}
		}
		.nube{
			margin-left: $small-margin;
			a{
				color: $medium-green;
				font-weight: $light;
				padding-right: $mini-margin;
				border-right: 1px solid $medium-green;
				margin-right: $mini-margin;
			}
		}
	}
}
#login{
	background-image: url('../img/usuario.jpg');
	background-position: center;
	background-size: cover;
	padding-bottom: $large-margin;
	h3{
		font-size: 36px;
		color: $white;
		margin-top: $large-margin;
		text-align: center;
		font-weight: $light;
		margin-bottom: $large-margin;
	}
	label{
		font-weight: $bold;
		color: $white;
		margin-bottom: $small-margin;
	}
	form{
		input{
			margin-bottom: $normal-margin;
		}
	}
	button{
		width: 100%;
		margin-bottom: $normal-margin;
	}
	p{
		text-align: center;
		font-size: $button-size;
		a{
			color: $white;
		}
	}
	.info{
		margin-top: $normal-margin;
		p{
			text-align: left;
			color: $white;
			span{
				font-weight: $bold;
				margin-bottom: $small-margin;
			}
		}
		h4{
			color: $white;
			font-size: $title-size;
			font-weight: $light;
			margin-bottom: $small-margin;
		}
	}
}
#login.propietarios{
	background-image: url('../img/propietario.jpg');
	.info{
		margin-bottom: $normal-margin;
		p, h4{
			text-align: center;
		}
	}
}
#registro-propietarios{
	text-align: center;
	padding-top: $large-margin;
	padding-bottom: $large-margin;
	h2{
		font-size: $title-size;
		color: $brown;
		font-weight: $bold;
		margin-bottom: $large-margin;
		span{
			font-weight: $light;
			color: $dark-green;
			font-weight: $light;
			font-size: $header-size;
		}
	}
	label{
		span{
			font-weight: $light;
			font-size: $title-size;
			color: $black;
			display: block;
		}
	}
	input[type="file"]{
		height: auto !important;
		border: 0px !important;
	}
	.precios{
		.ficha{
			transition: 0.4s;
			cursor: pointer;
			width: 20%;
			float: left;
			padding-left: 0.5%;
			padding-right: 0.5;
			.header{
				border: 1px solid $light-green;
				padding: $small-margin;
				transition: 0.4s;
				h2{
					margin-bottom: $mini-margin;
					font-size: 36px;
					font-weight: $light;
					color: $dark-green;
				}
				h3{
					color: $brown;
					font-weight: $light;
				}
				button{
					padding: 10px 15px;
				}
			}
			.content{
				background: $gray;
				padding: $mini-margin;
				margin-top: $mini-margin;
				transition: 0.4s;
				border: 1px solid $gray;
				p{
					margin: 0px;
					span{
						font-size: $button-size;
						color: $medium-green;
					}
				}
				p.observaciones{
					font-size: $small-size !important;
					font-weight: $bold;
					color: $dark-green;
				}
			}
		}
		.ficha:hover{
			transition: 0.4s;
			transform: scale(1.1);
			.header{
				background: $dark-green;
				box-shadow: 0px 5px 15px rgba(0,0,0,0.15);
				border: 1px solid $dark-green;
				h2, h3, p{
					color: $white;
				}
			}
			.content{
				box-shadow: 0px 5px 15px rgba(0,0,0,0.15);
			}
			.content:hover{
				transition: 0.4s;
				transform: scale(1.1);
				background: $white;
				border: 1px solid $gray;
			}
		}
	}
	.anuncio-rural{
		h2{
			margin-top: $large-margin;
			margin-bottom: $normal-margin !important;	
		}		
		.anuncio{
			text-align: center;
			font-size: $important-size !important;
			font-weight: $light;
			margin-bottom: $normal-margin;
			span{
				color: $medium-green;
			}
		}
	}
	form{
		margin-bottom: $normal-margin;
		h3{
			font-weight: $light;
			color: $dark-green;
			margin-bottom: $normal-margin;
		}
		label{
			text-align: left;
			font-weight: $bold;
			color: $dark-green;
			margin-bottom: $small-margin;
		}
		input{
			margin-bottom: $normal-margin;
		}
		.radio{
			display: inline;
			margin-right: $normal-margin;
			font-weight: $light;
			float: left;
			margin-top: $normal-margin;
			input{
				height: auto !important;
			    margin-bottom: 0px;
			    vertical-align: middle;
			}

		}
		.radio.block{
			display: block;
			width: 100%;
			margin-right: 0px;
			margin-bottom: $small-margin;
			margin-top: 0px;
		}
	}
	.pago{
		label{
			input, p{
				width: auto;
				display: inline;
				margin-left: $mini-margin;
				margin-right: $mini-margin;
				margin-bottom: 0px;
			}
		}
		
		.radio{
			margin-top: $small-margin;
		}
		.banco{
			text-align: left !important;
			display: block;
			font-weight: $bold;
			margin: 0px;
		}
		p{
			text-align: left;
			span{
				font-weight: $bold;
			}
		}
	}	
}
#registro-propietarios.anuncio{
	form{
		h2{
			margin-top: $large-margin;
			margin-bottom: $normal-margin;
		}
		p{
			text-align: left;
			span{
				font-weight: $bold;
			}
		}
		.radio{
			width: 100%;
			margin-top: $mini-margin;
			span{
				font-weight: $bold;
			}
		}
		button{
			width: 100%;
			margin-top: $small-margin;
		}
	}
}
#ficha-casa{
	padding-bottom: $large-margin;
	.cabecera{
		background: $dark-green;
		padding-top: $normal-margin;
		padding-bottom: $large-margin;
		h1{
			padding-top: 0px;
			padding-bottom: 0px;
		}
		h3{
			color: $light-green;
		}
	}
	.tabs-content{
		position: relative;
		background: none;
		border: 0px;
		ul.tabs{
			height: 60px;
			margin-top: -57px;
			background: none;
			border: 0px;
			li{
				height: 60px;
				border: 0px;
				a{
					background: $white;
					padding: 22px;
					border: 0px;
					color: $dark-green;
					font-size: $normal-size;
					box-shadow: inset 3px 0px 5px rgba(0, 0, 0, 0.25);
				}
				a[aria-selected='true']{
					font-weight: $bold;
					box-shadow: 1px -9px 15px rgba(0,0,0,0.25);
					border-left: 1px solid $gray;
				}
			}

		}
	}
	.tabs-panel{
		margin-top: $normal-margin;
		.galeria{
			position: relative;
			.badge-gr{
				position: absolute;	
				right: 0;
				margin-top: -20px;
			}
			img.principal{
				width: 100%;
				margin-bottom: $small-margin;
			}
			.columns:last-child{
				float: left !important;
			}
		}
		.informacion-basica{
			h2{
				line-height: 1;
				font-size: $header-size;
				font-weight: $light;
				color: $dark-green;
			}
			.rating{
				margin-top: $small-margin;
				margin-bottom: $normal-margin;
				img{
					margin-bottom: $mini-margin;
				}
				.btn{
					padding: 10px !important;
					display: inline-block;
				}
			}
			p{
				margin-top: $mini-margin;
				margin-bottom: 0px;
				span{
					font-weight: $bold;
					color: $dark-green;
				}
			}
			button{
				padding: 10px 15px;
				margin-left: $mini-margin;
				font-size: $normal-size;
			}
		}
		.informacion-contacto{
			margin-top: $normal-margin;
			p{
				font-size: $important-size !important;
				font-weight: $light;
				color: $dark-green;
				img{
					width: 20px;
					margin-right: $mini-margin;
				}
			}
		}
		.formulario-rapido{
			margin-top: $normal-margin;
			h3{
				font-size: $title-size;
				font-weight: $light;
				color: $brown;
				margin-bottom: $small-margin;
				span{
					font-weight: $bold;
				}
			}
		}
		.rrss{
			border: 1px solid $medium-green;
			padding: $small-margin;
			a{
				color: $medium-green;
				width: 100%;
				display: block;
				margin-bottom: $mini-margin;
				transition: 0.4s;
				margin-bottom: $small-margin;
				img{
					transition: 0.4s;
					width: 15px;
					margin-right: $mini-margin;
				}
				p{
					display: inline-block;
					font-size: $button-size;
					transition: 0.4s;
					margin: 0px;
				}
			}
			a:hover{
				transition: 0.4s;
				img{
					transition: 0.4s;
					width: 25px;
					text-align: center;
					margin-left: 40%;
				}
				p{
					display: none;
					transition: 0.4s;
				}
			}
		}
		.botones{
			margin-top: $large-margin;
			margin-bottom: $large-margin;
			button{
				width: 47%;
			}
			.btn-primary{
				margin-right: 2%;
			}
			.btn-border{
				margin-left: 2%;
			}
		}
		.descripcion{
			ul.tabs{
				height: auto;
				margin-top: $small-margin;
				li{
					height: auto;
					a{
						box-shadow: none;
						font-weight: $bold;
						color: $medium-green;
						padding: 0px;
						padding-right: $small-margin;
					}
					a[aria-selected='true']{
						border-left: 0px;
					} 
				}
			}
			.tabs-panel{
				margin-top: $small-margin;
				padding: 0px;
			}
		}
		.actividades, .lugares-interes, .comer-comprar, .como-llegar, .ultimas-opiniones, .equipamiento, .disponibilidad, .servicios{
			margin-top: $normal-margin;
		}
		.como-llegar{
			margin-bottom: $normal-margin;
			.coordenadas{
				font-weight: $bold;
				color: $dark-green;
				margin-bottom: $small-margin;
			}
			iframe{
				margin-top: $mini-margin;
			}
		}
		.ultimas-opiniones{
			border-top: 1px solid $gray;
			padding-top: $normal-margin;
			.opinion{
				margin-bottom: $small-margin;
				.nombre{
					color: $medium-green;
					font-weight: $bold;
					display: inline;
				}
				.puntuacion{
					display: inline;
					img{
						width: 15px;
						margin-left: 5px;
					}
				}
				.titulo{
					font-weight: $bold;
					margin-top: $mini-margin;
				}
				p, .estancia{
					margin-bottom: 0px;
				}
				.estancia{
					color: $brown;
				}
			}
			a{
				font-weight: $bold;
				color: $medium-green;
			}
		}
		h4{
			font-size: $title-size;
			color: $dark-green;
			font-weight: $bold;
			margin-bottom: $small-margin;
		}
		.equipamiento, .servicios{
			width: 49%;
			display: inline-block;
			padding-left: $mini-margin;
			padding-right: $mini-margin;
			.small-6.columns{
				width: 100%;
			}
			h4{
				text-align: left;
			}
			ul{
				margin-bottom: 0px;
				li{
					font-size: $normal-size;
					font-weight: $light;
				}
			}
		}
		.banner{
			background: url('../img/banner-anuncio.jpg');
			border: 2px solid #000;
			text-align: center;
			padding-top: $large-margin;
			padding-bottom: $large-margin;
			background-size: cover;
			position: relative;
			margin-top: $normal-margin;
			margin-bottom: $normal-margin;
			span{
				color: $white;
				font-size: $small-size;
				position: absolute;
				top: 0;
				left: 0;
			}
			h3{
				color: $light-green;
				font-weight: $light;
				font-size: 36px;
			}
			p{
				color: $white;
				font-size: $title-size !important;
			}
		}
		.blog{
			.post{
				margin-bottom: $mini-margin;
				img{
					width: 100%;
				}
				a{
					font-size: $important-size;
					color: $dark-green;
					font-weight: $bold;
				}
			}
			a{
				color: $medium-green;
				margin-top: $normal-margin;
				font-weight: $bold;
			}
		}
	}
	#fotos, #videos, #tarifas-disponibilidad, #opiniones, #ofertas{
		.informacion-basica{
			margin-bottom: $normal-margin;
			h2{
				font-size: 36px;
			}
			.rating{
				margin-top: $mini-margin;
				img{
					width: 20px;
				}
				button{
					display: block;
					margin-top: $mini-margin;
					margin-left: 0px;
				}
			}
		}
		button{
			margin-bottom: $small-margin;
			width: 100%;
		}
		.informacion-contacto{
			margin-top: $small-size;
			margin-bottom: $normal-margin;
			p{
				font-size: $normal-size !important;
			}
		}
		table{
			border: 0px;
			width: 100%;
			thead{
				background: none;
				border-top: 0px;
				border-left: 0px;
				border-right: 0px;
				border-bottom: 1px solid $dark-green;
				tr{
					background: none;
					th{
						background: none;
						color: $dark-green;
					}
				}
			}
			tbody{
				border: 0px;
				tr{
					td{
						font-weight: $light;
						font-size: $normal-size;
					}
				}
			}
		}
	}	
	#opiniones{
		.ultimas-opiniones{
			border-top: 0px;
			padding-top: 0px;
			margin-top: 0px;
			.opinion{
				border-bottom: 1px solid $gray;
				padding-bottom: $small-margin;
			}
		}
		.tu-opinion{
			.rating{
				margin-bottom: $small-margin;
				label{
					font-size: $important-size;
					font-weight: $light;
					display: inline;
					margin-right: $mini-margin;
				}
				img{
					margin-top: -5px;
				}
			}
			input{
				margin-bottom: $small-margin;
				height: 40px !important;
			}
			textarea{
				margin-bottom: $small-margin;
			}
		}
	}
	#ofertas{
		.oferta{
			width: 100%;
			border: 1px solid $light-gray;
			padding: $small-margin;
			margin-top: $small-margin;
			position: relative;
			min-height: 450px;
			h3{
				margin-top: $small-margin;
				span{
					font-weight: $bold;
					display: block;
				}
			}
			.fecha{
				img{
					width:20px;
					margin-right: $mini-margin;
				}
				span{
					color: $medium-green;
					font-weight: $bold,
				}
			}
			.precio{
				text-align: center;
				background: $medium-green;
				font-size: 30px !important;
				color: $white;
				position: absolute;
				right: 0;
				top: 0;
				padding: 5px;
				font-weight: $bold;
				line-height: 1.2;
				span{
					display: block;
					font-size: 10px !important;
					color: $white;
				}
			}
		}
		.columns:last-child{
			float: left;
		}
	}
}
#ficha-casa.club{
	.cabecera{
		background-image: url('../img/cabecera-club.jpg');
		background-size: cover;
		padding-top: $large-margin;
		padding-bottom: $large-margin;
		h1{
			font-size: $header-size;
			font-weight: $light;
			margin: 0px;
			padding: 0px;
		}
		h3{
			font-size: $title-size;
			color: $light-green;
			margin-bottom: $normal-margin;
		}
	}
}

#contacto{
	text-align: center;
	margin-top: $large-margin;
	margin-bottom: $large-margin;
	h1{
		color: $dark-green;
		font-weight: $light;
		span{
			font-weight: $bold;
			color: $black;
		}
	}
	form{
		margin-top: $normal-margin;
		label{
			text-align: left;
			font-weight: $bold;
			color: $dark-green;
			margin-bottom: $mini-margin;
		}
		input, textarea{
			margin-bottom: $small-margin;
		}
		button{
			min-width: 200px;
			margin-left: $small-margin;
			margin-right: $small-margin;
			margin-top: $normal-margin;
		}
		a{
			display: block;
			margin-top: $normal-margin;
			color: $medium-green;
			font-size: $button-size;
		}
	}
}
#panel-usuario{
	padding-top: $normal-margin;
	padding-bottom: $normal-margin;
	h1{
		text-align: center;
		font-weight: $light;
		color: $dark-green;
		margin-bottom: $large-margin;
		span{
			font-weight: $bold;
			color: $black;
		}
	}
	.usuario, .password{
		margin-bottom: $normal-margin;
		h3{
			font-weight: $light;
			color: $dark-green;
			margin-bottom: $small-margin;
			text-align: left;
		}
		form{
			label{
				font-weight: $bold;
				color: $dark-green;
				margin-bottom: $mini-margin;
			}
			input{
				margin-bottom: $small-margin;
			}
			button{
				width: 100%;
				margin-top: $small-margin;
			}
		}
	}
	h3{
		text-align: center;
		font-weight: $light;
		color: $dark-green;
		margin-bottom: $normal-margin;
	}
	.favoritos, .opinion{
		margin-bottom: $normal-margin;
		img{
			width: 100%;
		}
		h4{
			font-size: $button-size;
			color: $dark-green;
			margin: 0px;
			font-weight: $bold;
		}
		p{
			margin: 0px;
			span{
				font-weight: $bold;
			}
		}
	}
	.opinion{
		.nombre{
			font-size: $button-size;
			font-weight: $bold;
		}
		.puntuacion{
			margin-bottom: $mini-margin;
			img{
				width: 20px;
			}
		}
		.titulo{
			font-weight: $bold;
		}
		.estancia{
			color: $dark-green;
		}
	}
	.negocio{
		margin-bottom: $normal-margin;
		h4{
			font-weight: $bold;
			font-size: $important-size;
			margin-top: $small-margin;
			margin-bottom: 0px;
			color: $dark-green;
		}
		p{
			margin: 0px;
		}
	}
	.solicitud{
		margin-top: $large-margin;
		.input-group{
			margin-top: $normal-margin;
			input{
				width: 100%;
				margin: 0 auto;
			}
		}
	}
}
#panel-propietario{
	.cabecera{
		background: $brown;
		h1{
			color: $light-green;
			span{
				color: $white;
				font-weight: $bold;
			}
		}
	}
	.acciones{
		margin-top: $normal-margin;
		button{
			width: 100%;
			text-align: left;
			font-size: $normal-size;
			margin-bottom: $small-margin;
		}
		h4{
			color: $dark-green;
			font-weight: $bold;
			margin-top: $small-margin,
		}
		form{
			label{
				font-weight: $bold;
				color: $dark-green;
				margin-bottom: $mini-margin;
			}
			input{
				margin-bottom: $small-margin;
			}
		}
	}
	.tabs-content{
		position: relative;
		background: none;
		border: 0px;
		ul.tabs{
			height: 60px;
			margin-top: -66px;
			background: none;
			border: 0px;
			li{
				height: 60px;
				border: 0px;
				a{
					background: $white;
					padding: 22px;
					border: 0px;
					color: $dark-green;
					font-size: $normal-size;
					box-shadow: inset 3px 0px 5px rgba(0, 0, 0, 0.25);
				}
				a[aria-selected='true']{
					font-weight: $bold;
					box-shadow: 1px -9px 15px rgba(0,0,0,0.25);
					border-left: 1px solid $gray;
				}
			}

		}
	}
	.tabs-panel{
		margin-top: $small-margin;
		.btn{
			margin-top: $normal-margin;
		}
		table{
			border: 0px;
			width: 100%;
			margin-top: $small-margin;
			thead{
				background: none;
				border-top: 0px;
				border-left: 0px;
				border-right: 0px;
				border-bottom: 1px solid $dark-green;
				tr{
					background: none;
					th{
						background: none;
						color: $dark-green;
					}
				}
			}
			tbody{
				border: 0px;
				tr{
					td{
						font-weight: $light;
						font-size: $normal-size;
					}
				}
			}
		}
		form{
			.checks{
				margin-bottom: $small-margin,
			}
			label{
				font-weight: $bold;
				color: $dark-green;
				margin-bottom: $mini-margin;
				span{
					font-size: $title-size;
					font-weight: $light;
				}
				.comment{
					font-size: $small-size;
					color: $black;
				}
			}
			input{
				width: 100%;
				margin-bottom: $small-margin;
			}
			textarea{
				margin-bottom: $normal-margin;
			}
			.radio{
				display: inline;
				margin-right: $normal-margin;
				font-weight: $light;
				float: left;
				margin-top: $normal-margin;
				input{
					height: 20px !important;
				    margin-bottom: 0px;
				    vertical-align: middle;
				    width: 20px;
				    margin-right: 5px;
				}

			}
			.radio.block{
				display: block;
				width: 100%;
				margin-right: 0px;
				margin-bottom: $small-margin;
				margin-top: 0px;
			}
		}
	}
	#descripcion{
		.btn{
			margin-top: 0px;
		}
	}
	#equipo-servicio{
		form{
			margin-bottom: $small-margin;
		}
	}
	#fotos-videos{
		h4{
			color: $dark-green;
			margin-bottom: $small-margin;
		}
		.cont{
			font-size: $button-size !important;
			color: $brown;
			span{
				font-weight: $bold;
			}
		}
		input[type="file"]{
			border: 0px !important;
			margin-bottom: 0px;
		}
		hr{
			max-width: 100%;
			margin-bottom: $normal-margin;
			margin-top: $normal-margin !important;
		}
		input{
			width: auto;
		}
		input[type="url"]{
			width: 300px;
			display: inline-block;
		}
		button{
			margin-top: $small-margin;
		}		
	}
	#opiniones{
		h4{
			color: $dark-green;
			font-weight: $light;
			span{
				background: $medium-green;
				font-size: 20px;
				color: $white;
				font-weight: $bold;
				text-transform: uppercase;
				padding: 5px 10px 8px 10px;
			}
		}
		a{
			color: $medium-green;
			display: inline-block;
			border-bottom: 1px solid $medium-green;
		}
		form{
			margin-top: $normal-margin;
			button{
				margin-top: 35px;
				width: 100%;
				padding: 17px;
			}
		}
	}
	#tarifas{
		h4{
			font-weight: $light;
			color: $dark-green;
			margin-bottom: $small-margin;
		}
		p{
			display: inline-block;
			margin-right: $mini-margin;
		}
		.radio.block{
			margin-right: $mini-margin;
			display: inline-block;
			input{
				height: auto !important;
				vertical-align: text-top;
			}
		}
		table{
			margin-bottom: $normal-margin;
			tbody{
				td{
					a{
						display: block;
						color: $medium-green;
						border-bottom: 1px solid $medium-green;
						display: inline-block;
						margin-right: $mini-margin;
					}
				}
			}
		}
		form{
			input, textarea{
				margin-bottom: $small-margin;
			}
		}
		.btn{
			margin-top: $small-margin;
			margin-bottom: $normal-margin;
		}
	}
	#estadisticas{
		h4{
			color: $dark-green;
			font-weight: $light;
		}
		a{
			color: $medium-green;
			display: inline-block;
			border-bottom: 1px solid $medium-green;
			margin-right: $small-margin;
		}
		.info{
			margin-top: $normal-margin;
			p{
				font-size: $button-size !important;
				span{
					font-size: $important-size;
					font-weight: $bold;
					color: $dark-green;
				}
				select{
					width: 200px;
					margin-left: $mini-margin;
					height: 35px !important;
				}
			}
		}
	}
	#ofertas{
		h4{
			font-weight: $light;
			color: $dark-green;
			margin-bottom: $small-margin;
		}
		a{
			color: $medium-green;
			border-bottom: 1px solid $medium-green;
		}
		table{
			margin-top: $normal-margin,
		}
	}
	#destacar-mi-alojamiento{
		h4{
			color: $dark-green;
			margin-bottom: $small-margin;
		}
		.fichas{
			border: 1px solid $light-gray;
			width: auto !important;
			padding: $small-margin;
			margin-bottom: $small-margin;
			margin-top: $small-margin;
			display: table;
			.ficha{
				font-size: $important-size !important;
				display: table;
				a{
					color: $medium-green;
					margin-right: $mini-margin;
					border-bottom: 1px solid $medium-green;
				}
			}
		}
		p.comment {
		    color: #747474;
		    font-weight: $bold;
		    a{
		    	color: $medium-green;
		    	font-weight: $light;
		    	border-bottom: 1px solid $medium-green;
		    }
		}
	}
	#dar-de-baja{
		h4{
			color: $dark-green;
			margin-bottom: $small-margin;
		}
		p{
			margin-bottom: 0px !important;
			label{
				display: inline-block;
				margin-left: $mini-margin;
				input{
					height: auto !important;
				}
				span{
					font-weight: $bold;
				}
			}
		}
		textarea{
			margin-bottom: $normal-margin;
			margin-top: $small-margin;
		}
	}
	#ficha-info-turistica{
		form{
			margin-top: $normal-margin;
			label{
				color: $dark-green;
				margin-bottom: $mini-margin;
				font-weight: $bold;
			}
			input{
				margin-bottom: $small-margin,
			}
		}
		.btn{
			margin-top: $small-margin;
		}
	}
}
.inftur{
	max-width: 90%;
	padding-top: $normal-margin;
	padding-bottom: $normal-margin;
	text-align: center;
	margin: 0 auto;
	h1{
		font-weight: $light;
		color: $dark-green;
		font-size: $banner-size;
		margin-bottom: $normal-margin;
	}
	.etiq{
		font-family: $important-size;
		font-weight: $light;
		color: $brown;
	}
	table{
		margin-top: $normal-margin;
		tbody{
			border: 0px;
			tr:nth-child(even) {
			    background-color: $white;
			}
			tr{
				td{
					span.etiq{
						color: $brown;
						font-weight: $bold;
						strong{
							color: red;
						}
					}
					input[type="checkbox"] {
					    height: 20px;
					    vertical-align: -webkit-baseline-middle;
					    margin-top: 7px;
					    margin-right: 5px;
					}
				}
			}
		}
		input.boton{
			background: $brown;
			color: $white;
			font-weight: $bold;
			padding-left: $small-margin;
			padding-right: $small-margin;
		}
	}
}
#popup{
	position: fixed;
	width: 100%;
	display: none;
	.popup-gr{
		padding: $large-margin;
		background: $white;
		text-align: center;
		box-shadow: 0px 0px 10px #000;
		width: 100%;
		margin-top: 50px
	}
}

@media (max-width: 1490px){
	.row{
		max-width: 85% !important;;
	}
	#ofertas{
		.listado{
			.oferta{
				h3, h2{
					font-size: $normal-size;
				}
			}
		}
	}
}
@media (max-width: 64em){
	#registro-propietarios{
		.precios{
			.ficha{
				width: 33%;
				padding-left: 1%;
				padding-right: 1%;
			}
			.ficha:nth-child(4), .ficha:nth-child(5){
				width: 50%;
				margin-top: $normal-margin;
			}
		}
	}
	#menu{
		nav{
			ul{
				li {
				    display: table-cell;
				    width: 31%;
				    margin-right: 0px !important;
				    text-align: center;
				}
			}
		}
	}
	#menu {
		.principal {
			.row {
				ul{ 
					li:first-child {
					    display: block;
					    text-align: center;
					    width: 100% !important;
					}
				}
			}
		}
	}
	#mapa{
		.medium-6.columns{
			form{
				width: 75%;
			}
		}
	}
}
@media (max-width: 40em){
	#casas-destacadas, #nuevos{
		.listado{
			.destacado{
				height: auto !important;
			}
		}
	}
	#registro-propietarios{
		.precios{
			.ficha{
				width: 100%;
				margin-top: $normal-margin;
			}
			.ficha:nth-child(4), .ficha:nth-child(5){
				width: 100%;
				margin-top: $normal-margin;
			}
		}
	}
}


#popup {
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: none;
}
#popup #popup-gr {  
    padding: 80px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 10px #000;
    width: 100%;
    margin-top: 50px;
}

/* MAPS.CSS */

#busqueda1{
	top:165px;
	left:0px;
	width:428px;
	border:solid thin #C0C0C0;
	background-color:#EFEFEF;
	padding:8px 8px 8px 34px;height:375px}
#busqueda1 h3, #busqueda1 p{
	width:150px;
	margin:0px 10px 0px 0px;
	height:20px;
	color:#000000;
	font-family:Geneva,Arial;
	font-size:12px;
	float:left
}

#mapas{position:relative;top:$large-margin;left:0px;width:460px;height:320px;overflow:hidden}
#mapapolitico{position:absolute;top:0px;width:378px;height:310px;background-image:url(/assets/img/Mapaspain1.jpg);background-repeat:no-repeat}

#Almeria{position:absolute;top:221px;left:176px;width:28px;height:30px}
#Alava{position:absolute;top:32px;left:162px;width:25px;height:12px}
#Cadiz{position:absolute;top:238px;left:76px;width:31px;height:28px}
#Cordoba{position:absolute;top:187px;left:112px;width:26px;height:35px}
#Granada{position:absolute;top:219px;left:144px;width:24px;height:27px}
#Huelva{position:absolute;top:195px;left:52px;width:26px;height:39px}
#Jaen{position:absolute;top:189px;left:139px;width:39px;height:25px}
#Malaga{position:absolute;top:235px;left:108px;width:35px;height:25px}
#Sevilla {position:absolute;top:201px;left:82px;width:28px;height:34px}
#Huesca{position:absolute;top:44px;left:225px;width:32px;height:25px}
#Teruel{position:absolute;top:99px;left:207px;width:27px;height:26px}
#Zaragoza{position:absolute;top:70px;left:203px;width:24px;height:22px}
#Asturias{position:absolute;top:10px;left:70px;width:53px;height:20px}
#Baleares{position:absolute;top:118px;left:275px;width:87px;height:62px}
#Las_Palmas{position:absolute;top:205px;left:292px;width:82px;height:67px}
#SC_Tenerife{position:absolute;top:221px;left:226px;width:65px;height:52px}
#Cantabria{position:absolute;top:14px;left:132px;width:23px;height:17px}
#Albacete{position:absolute;top:162px;left:180px;width:28px;height:25px}
#Ciudad_Real{position:absolute;top:159px;left:127px;width:44px;height:27px}
#Cuenca{position:absolute;top:123px;left:178px;width:25px;height:34px}
#Guadalajara{position:absolute;top:99px;left:163px;width:35px;height:18px}
#Toledo{position:absolute;top:132px;left:118px;width:49px;height:18px}
#Avila{position:absolute;top:109px;left:114px;width:18px;height:14px}
#Burgos{position:absolute;top:41px;left:143px;width:19px;height:32px}
#Leon{position:absolute;top:36px;left:76px;width:44px;height:21px}
#Palencia{position:absolute;top:37px;left:122px;width:14px;height:34px}
#Salamanca{position:absolute;top:94px;left:75px;width:31px;height:23px}
#Segovia{position:absolute;top:88px;left:131px;width:20px;height:12px}
#Soria{position:absolute;top:70px;left:169px;width:23px;height:20px}
#Valladolid{position:absolute;top:73px;left:111px;width:19px;height:18px}
#Zamora{position:absolute;top:65px;left:87px;width:22px;height:26px}
#Barcelona{position:absolute;top:69px;left:285px;width:29px;height:22px}
#Girona{position:absolute;top:48px;left:305px;width:23px;height:20px}
#Lleida{position:absolute;top:47px;left:263px;width:20px;height:24px}
#Tarragona{position:absolute;top:88px;left:254px;width:31px;height:24px}
#Alicante{position:absolute;top:176px;left:223px;width:30px;height:27px}
#Castellon{position:absolute;top:112px;left:239px;width:21px;height:30px}
#Valencia{position:absolute;top:143px;left:216px;width:33px;height:27px}
#Badajoz{position:absolute;top:161px;left:68px;width:34px;height:31px}
#Caceres{position:absolute;top:126px;left:72px;width:36px;height:33px}
#A_Coruna{position:absolute;top:5px;left:11px;width:36px;height:31px}
#Lugo{position:absolute;top:17px;left:51px;width:19px;height:31px}
#Ourense {position:absolute;top:50px;left:42px;width:26px;height:17px}
#Pontevedra{position:absolute;top:41px;left:18px;width:21px;height:19px}
#La_Rioja{position:absolute;top:51px;left:167px;width:23px;height:11px}
#Madrid{position:absolute;top:107px;left:141px;width:18px;height:21px}
#Murcia{position:absolute;top:194px;left:197px;width:24px;height:23px}
#Navarra{position:absolute;top:31px;left:194px;width:15px;height:26px}
#Guipuzcoa{position:absolute;top:13px;left:182px;width:13px;height:18px}
#Vizcaya{position:absolute;top:14px;left:164px;width:16px;height:15px}
#Azores{position:absolute;top:235px;left:0px;width:74px;height:36px}
#Alentejo{position:absolute;top:181px;left:11px;width:40px;height:32px}
#Algarve{position:absolute;top:216px;left:7px;width:44px;height:15px}
#Beiras{position:absolute;top:97px;left:17px;width:52px;height:30px}
#Lisboa_VTejo{position:absolute;top:128px;left:0px;width:31px;height:48px}
#Madeira{position:absolute;top:251px;left:144px;width:25px;height:21px}
#Porto_e_Norte{position:absolute;top:68px;left:20px;width:54px;height:28px}