//COLORS:
$gray: #EEEEEE;
$light-green: #b8c9ab;
$medium-green: #849f73;
$dark-green: #4e5635;
$brown: #4d3725;
$white: #fff;
$black: #333;

//FONT:
$ubuntu: 'Ubuntu';

//FONT-WEIGHT
$light: 100;
$normal: 300;
$bold: 700;

//MARGINS
$normal-margin: 40px;
$large-margin: 80px;
$small-margin: 20px;
$mini-margin: 10px;

//FONT-SIZE
$small-size: 12px;
$normal-size: 14px;
$button-size: 16px;
$important-size: 18px;
$title-size: 24px;
$header-size: 60px;
$banner-size: 48px;